<template>
  <suc-text-area-field-component
    :title="$t('pages.permission.absences.comments')"
    v-bind="$attrs"
    v-on="$listeners"
  ></suc-text-area-field-component>
</template>

<script>
import { SucTextAreaFieldComponent } from "@/components/form";

export default {
  components: {
    SucTextAreaFieldComponent,
  },
};
</script>

<style></style>
