<template>
  <vue-modal
    :modal-title="$t('pages.permission.confirm_waiting')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    :showPrimaryFooterButton="confirmWaiting"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <suc-form-has-error :form="form" />

      <template v-if="confirmWaiting">
        <div class="row">
          <div class="col">
            <appointment-status-field id="status" name="status" :form="form" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <absence-comment-field id="absence-comment" name="comments" :form="form" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row" v-if="isInitialized && detail.id">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.id')"
              :value="detail.id"
            />
          </div>
        </div>
        <div class="row" v-if="isInitialized && detail.isPermission()">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.status')"
              :value="permissionStatus"
            />
          </div>
        </div>
        <div class="row" v-if="isInitialized">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.type_absenses')"
              :value="detail.causeOfAbsenceDescription"
            />
          </div>
        </div>
        <div class="row" v-if="isInitialized">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.start_date')"
              :value="detail.dateStart"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" :withTime="true" />
              </template>
            </suc-text-form-show-field>
          </div>
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.end_date')"
              :value="detail.dateEnd"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" :withTime="true" />
              </template>
            </suc-text-form-show-field>
          </div>
        </div>
        <div class="row" v-if="isInitialized && detail.comments">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.comments')"
              :value="detail.comments"
            />
          </div>
        </div>
        <div class="row" v-if="isInitialized">
          <div class="col">
            <div class="form-group">
              <label class="label-description"> {{ $t("pages.permission.attachments") }}</label>
              <div>
                <div class="description">
                  <a
                    target="_blank"
                    href="#"
                    v-for="attachment in detail.attachments"
                    :key="attachment.key"
                    @click.prevent="downLoadAttachment(attachment)"
                    >{{ attachment.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </vue-modal>
</template>

<script>
import { ModalAlert } from "@/components/modal";

import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { SHOW_ABSENCE_REVISION_ADD_MODAL } from "@/pages/responsible/planing/data/events";

import { AppointmentStatusField } from "@/pages/components/fields";
import { AbsenceCommentField } from "@/pages/employee/permission/absences/fields";
import { Form, FORM_ERRORS, SucFormHasError } from "@/components/form";
import { RevisionAppointment } from "@/pages/components/data";

import { RequiredRule } from "@/components/form/data/rules";

import api from "@/api/appointment";
import { FormattedDateComponent } from "@/components/formatted";
import { FinalAbsence } from "@/pages/components/data";
import * as Sentry from "@sentry/vue";
import { ABSENCE_UPDATED_EVENT } from "@/pages/employee/permission/absences/components";

export default {
  components: {
    VueModal,
    AppointmentStatusField,
    AbsenceCommentField,
    SucFormHasError,
    FormattedDateComponent,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      appointment: null,
      finalAbsence: null,
      form: new Form({
        data: {
          status: null,
          comments: null,
        },
      }),
    };
  },
  computed: {
    isInitialized() {
      return this.$isset(this.finalAbsence);
    },
    confirmWaiting() {
      return this.$isset(this.appointment) && this.appointment.isConfirmWaiting();
    },
    fieldsRules() {
      let rules = [
        new RequiredRule({
          name: "status",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
      return rules;
    },
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  created() {
    EventBus.listen(SHOW_ABSENCE_REVISION_ADD_MODAL, ({ appointment }) => {
      this.appointment = appointment;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.form.reset();
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_ABSENCE_REVISION_ADD_MODAL);
  },
  methods: {
    getDetail(id, source) {
      this.isLoading = true;
      this.detail = null;
      api
        .getDetailAbsence({ employeeCode: this.employeeCode, id, source })
        .then((response) => {
          this.detail = new FinalAbsence().parse(response.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);

      if (this.form.validate()) {
        this.isLoading = true;

        const revision = new RevisionAppointment({
          id: this.appointment.id,
          status: this.form.status.id,
          message: this.$isset(this.form.comments) ? this.form.comments : "",
        });

        Sentry.setContext("sendData", { ...this.form.data(), id: this.appointment.id });

        api
          .revisionAppointment({ revision })
          .then(() => {
            this.$toasts.success(this.$t("pages.permission.absences.operation_completed"));
            this.clear();
            this.showModal = false;
            EventBus.fire(ABSENCE_UPDATED_EVENT);
          })
          .catch((error) => {
            if (error.message.toLowerCase() === "network error") {
              ModalAlert.error({ message: this.$t("components.messages.network_error") });
            } else {
              //unsigned error
              Sentry.captureException(error);
              let errorMessage = this.$t("components.messages.error");
              if (error?.response?.data?.errors) {
                errorMessage = error.response.data.errors[""][0];
              }
              console.error(errorMessage);
              this.form.errors.set(FORM_ERRORS, this.$t("components.messages.error"));
            }
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.form.reset();
    },
  },
};
</script>
