import AbsenceStartDayField from "@/pages/employee/permission/absences/fields/AbsenceStartDayField.vue";
import AbsenceEndDayField from "@/pages/employee/permission/absences/fields/AbsenceEndDayField.vue";
import AbsenceCommentField from "@/pages/employee/permission/absences/fields/AbsenceCommentField.vue";
import AbsenceTypeField from "@/pages/employee/permission/absences/fields/AbsenceTypeField.vue";
import AbsenceHourForFullShiftField from "@/pages/employee/permission/absences/fields/AbsenceHourForFullShiftField.vue";
import AbsenseHourForHalfShift from "@/pages/employee/permission/absences/fields/AbsenseHourForHalfShift.vue";
import AbsenceDayField from "@/pages/employee/permission/absences/fields/AbsenceDayField.vue";
import AbsenceFileField from "@/pages/employee/permission/absences/fields/AbsenceFileField.vue";
import AbsenceHourWithFullDayHours from "@/pages/employee/permission/absences/fields/AbsenceHourWithFullDayHours.vue";

export {
  AbsenceStartDayField,
  AbsenceEndDayField,
  AbsenceCommentField,
  AbsenceTypeField,
  AbsenceHourForFullShiftField,
  AbsenseHourForHalfShift,
  AbsenceDayField,
  AbsenceFileField,
  AbsenceHourWithFullDayHours,
};
