<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.edit_overtime')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    :secondaryFooterButtonTranslation="$t('components.actions.remove')"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onSecondaryButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="row mb-4" v-if="employeeInitialized">
        <div class="col">
          <h6>{{ $t("pages.planing-team-validate.employee_name") }}: {{ employee.fullName }}</h6>
        </div>
      </div>

      <suc-form-has-error :form="form" />

      <div class="row">
        <div class="col">
          <date-overtime-field :form="form" name="date" id="date" />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <extra-hour-field :form="form" name="qtyExtra" id="qty-extra" />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <is-night-hour-field :form="form" name="isNight" />
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import {
  SHOW_OVERTIME_EDIT_MODAL,
  EMPLOYEE_OVERTIME_UPDATED,
  EMPLOYEE_OVERTIME_DELETED,
} from "@/pages/responsible/planing/data/events";

import { DateOvertimeField, ExtraHourField, IsNightHourField } from "./fields";

import { Form, FORM_ERRORS, SucFormHasError } from "@/components/form";
import { RequiredRule } from "@/components/form/data/rules";

import api from "@/api/overtime";

export default {
  components: {
    VueModal,
    DateOvertimeField,
    ExtraHourField,
    IsNightHourField,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      employee: null,
      employeeData: null,
      form: new Form({
        data: {
          qtyExtra: null,
          date: null,
          isNight: false,
        },
      }),
    };
  },
  computed: {
    employeeInitialized() {
      return this.$isset(this.employee);
    },
    fieldsRules() {
      let rules = [
        new RequiredRule({
          name: "date",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
        new RequiredRule({
          name: "qtyExtra",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
      return rules;
    },
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  created() {
    EventBus.listen(SHOW_OVERTIME_EDIT_MODAL, ({ employee, data, overtime }) => {
      this.employee = employee;
      this.employeeData = data;
      this.overtime = overtime;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;
        this.clear();
        this.form.date = this.employeeData.date;
        this.form.qtyExtra = this.overtime.qtyExtra;
        this.form.isNight = this.overtime.isNight;

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_OVERTIME_EDIT_MODAL);
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);

      if (this.form.validate()) {
        this.isLoading = true;

        var data = this.form.data();
        data["employeeCode"] = this.employee.employeeCode;
        //because of DateOnly in net8 remove "T00:00:00"
        data["date"] = data["date"].replace("T00:00:00", "");

        api
          .updateOvertime(
            this.employee.employeeCode,
            this.employeeData.date,
            this.overtime.isNight,
            data
          )
          .then(() => {
            this.clear();
            this.showModal = false;
            this.$emit("changed");
            EventBus.fire(EMPLOYEE_OVERTIME_UPDATED, { employeeCode: this.employee.employeeCode });
          })
          .catch((error) => {
            let errorMessage = this.$t("components.messages.error");
            if (error?.response?.data?.errors) {
              errorMessage = error.response.data.errors[""][0];
            }
            this.form.errors.set(FORM_ERRORS, errorMessage);
            console.error(error.response);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    onSecondaryButtonClicked() {
      this.isLoading = true;
      api
        .removeOvertime(this.employee.employeeCode, this.employeeData.date, this.overtime.isNight)
        .then(() => {
          this.clear();
          this.showModal = false;
          this.$emit("changed");
          EventBus.fire(EMPLOYEE_OVERTIME_DELETED, { employeeCode: this.employee.employeeCode });
        })
        .catch((error) => {
          let errorMessage = this.$t("components.messages.error");
          if (error?.response?.data?.errors) {
            errorMessage = error.response.data.errors[""][0];
          }
          this.form.errors.set(FORM_ERRORS, errorMessage);
          console.error(error.response);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.form.errors.clear();
      this.form.reset();
    },
  },
};
</script>
