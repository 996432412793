import EmployeeWorkingWidget from "./EmployeeWorkingWidget.vue";
import EmployeeVacationsWidget from "./EmployeeVacationsWidget.vue";
import EmployeeRestingWidget from "./EmployeeRestingWidget.vue";
import EmployeeSickWidget from "./EmployeeSickWidget.vue";

export {
  EmployeeWorkingWidget,
  EmployeeVacationsWidget,
  EmployeeRestingWidget,
  EmployeeSickWidget,
};
