<template>
  <suc-select-component
    :title="$t('pages.permission.absences.type_absenses')"
    :options="options"
    label="descriptionCat"
    @input="onChangeField"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-options>{{ $t("components.fields.no_options") }}</template>
  </suc-select-component>
</template>

<script>
import { SucSelectComponent, SUC_SELECT_CHANGED_EVENT } from "@/components/form";
import api from "@/api/appointment";

import { AbsenceType } from "@/pages/components/data";
import { mapGetters } from "vuex";

export default {
  props: {
    withoutVacation: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SucSelectComponent,
  },
  data() {
    return {
      options: [],
      value: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["holidaysAbsenceCode"]),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { data } = await api.getTypeAbsenses();
      var filters = this.withoutVacation
        ? data.filter((x) => x.code !== this.holidaysAbsenceCode)
        : data;
      this.options = filters.map((x) => new AbsenceType().parse(x));
    },
    onChangeField(option) {
      this.value = option;
      this.$emit(SUC_SELECT_CHANGED_EVENT, this.$deepCopy(this.value));
    },
    clear() {
      this.value = null;
    },
  },
};
</script>

<style scoped></style>
