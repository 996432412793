import { render, staticRenderFns } from "./AbsenceTypeField.vue?vue&type=template&id=5329bfda&scoped=true&"
import script from "./AbsenceTypeField.vue?vue&type=script&lang=js&"
export * from "./AbsenceTypeField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5329bfda",
  null
  
)

export default component.exports