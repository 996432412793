<template>
  <suc-file-field-component
    :title="$t('pages.permission.absences.file')"
    v-bind="$attrs"
    v-on="$listeners"
    type="file"
  ></suc-file-field-component>
</template>

<script>
import { SucFileFieldComponent } from "@/components/form";

export default {
  components: {
    SucFileFieldComponent,
  },
};
</script>
