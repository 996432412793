<template>
  <div>
    <vue-data-table
      :table-columns="columns"
      :table-rows="rows"
      :is-loading="isLoading"
      :stickyTable="true"
      :empty-rows-message="$t('components.table.no_data')"
      @[needDataUpdateEvent]="init"
    >
      <template #table-row="props">
        <template v-if="props.cell.getColumn.id == 'employee.holidaysRemaining'">
          {{ props.cell.getValue }}
        </template>

        <template v-else-if="props.cell.getColumn.id == 'employee.hourBalance'">
          {{ props.cell.getValue }}
        </template>

        <template v-else-if="props.cell.getColumn.id == 'employee.fullName'">
          <div>
            {{ props.cell.getValue }}
          </div>
          <div>
            <span class="badge badge-pill badge-light pl-0"
              >{{ props.cell.getRaw.employee.employeeCode }}
            </span>
          </div>
        </template>
        <template v-else-if="!['actions'].includes(props.cell.getColumn.id)">
          <plan-week-or-monthItem-table-component
            :employeeData="props.cell.getByPathRaw"
            :employee="props.cell.getRaw.employee"
          />
        </template>
      </template>

      <template #table-action-menu="props">
        <router-link
          :to="{
            name: 'planing-team-validate',
            params: { code: props.row.raw.employee.employeeCode },
          }"
          class="dropdown-item"
        >
          {{ $t("pages.permission.validate_hours") }}
        </router-link>
        <a class="dropdown-item" @click.prevent="addAbsence(props.row.raw.employee)">
          {{ $t("pages.planing-team-validate.add_absence") }}
        </a>
        <a class="dropdown-item" @click.prevent="addWorkShift(props.row.raw.employee)">
          {{ $t("pages.planing-team-validate.add_workshift") }}
        </a>
        <a class="dropdown-item" @click.prevent="addOvertimeNight(props.row.raw.employee)">
          {{ $t("pages.planing-team-validate.add_overtime") }}
        </a>
        <router-link
          :to="{
            name: 'profile-employee',
            params: { code: props.row.raw.employee.employeeCode },
          }"
          class="dropdown-item"
        >
          {{ $t("pages.profile.tabs.personal_data.title") }}
        </router-link>
      </template>

      <template #description>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <absence-justified-day-legend class="m-1" />
            <absence-unjustified-day-legend class="m-1" />
            <absence-confirm-waiting-day-legend class="m-1" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <vacations-approved-day-legend class="m-1" />
            <vacation-confirm-waiting-day-legend class="m-1" />
            <vacations-need-meeting-day-legend class="m-1" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <off-day-legend class="m-1" />
            <sick-day-legend class="m-1" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <working-day-legend class="m-1" />
            <working-day-legend
              :badge="7"
              :title="$t('pages.permission.working_with_real_hours')"
              class="m-1"
            />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <overtime-day-legend :badge="1" :withTitle="true" class="m-1" />
            <overtime-night-legend :badge="1" :withTitle="true" class="m-1" />
          </div>
        </div>
      </template>
    </vue-data-table>
    <day-detail-component />
  </div>
</template>

<script>
import api from "@/api/plan";
import VueDataTable, { Column, VUE_DATA_TABLE_NEED_DATA_UPDATE } from "@/components/table";
import PlanWeekOrMonthItemTableComponent from "@/pages/responsible/planing/general/components/PlanWeekOrMonthItemTableComponent.vue";
import { PlanWeekOMonthFilter } from "@/pages/responsible/planing/general/data";

import { EmployeePlanData } from "@/pages/components/data";
import {
  SHOW_DAY_DETAIL_MODAL,
  SHOW_ABSENCE_ADD_MODAL,
  SHOW_WORK_SHIFT_ADD_MODAL,
  SHOW_OVERTIME_ADD_MODAL,
  EMPLOYEE_ABSENCE_ADDED,
  EMPLOYEE_WORK_SHIFT_ADDED,
  EMPLOYEE_OVERTIME_ADDED,
  EMPLOYEE_OVERTIME_UPDATED,
  EMPLOYEE_OVERTIME_DELETED,
} from "@/pages/responsible/planing/data/events";
import DayDetailComponent from "@/pages/responsible/planing/general/components/DayDetailComponent.vue";

import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  WorkingDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  OvertimeDayLegend,
  OvertimeNightLegend,
} from "@/pages/components/day-legend";

import { addDays, differenceInCalendarDays, isSaturday, isSunday } from "date-fns";

import { mapGetters } from "vuex";

export default {
  components: {
    VueDataTable,
    PlanWeekOrMonthItemTableComponent,
    AbsenceConfirmWaitingDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceJustifiedDayLegend,
    OffDayLegend,
    SickDayLegend,
    WorkingDayLegend,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    OvertimeDayLegend,
    OvertimeNightLegend,
    DayDetailComponent,
  },
  props: {
    filter: {
      type: PlanWeekOMonthFilter,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      raw: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack"]),
    needDataUpdateEvent() {
      return VUE_DATA_TABLE_NEED_DATA_UPDATE;
    },
    columns() {
      let columns = [];

      columns.push(
        new Column({
          id: "employee.fullName",
          name: "components.fields.day",
          styleHeader: {
            width: "400px !important",
            "min-width": "400px !important",
            "max-width": "400px !important",
            "text-align": "center",
          },
          styleCell: {
            width: "400px !important",
            "min-width": "400px !important",
            "max-width": "400px !important",
            "text-align": "left",
          },
          classCell: {},
        })
      );

      columns.push(
        new Column({
          id: "employee.holidaysRemaining",
          name: "pages.planing-team.general.holidaysRemainingShort",
          styleHeader: {
            "text-align": "center",
          },
          classCell: ["text-center"],
        })
      );

      if (this.activeHoursPack) {
        columns.push(
          new Column({
            id: "employee.hourBalance",
            name: "pages.planing-team.general.hourBalanceShort",
            styleHeader: {
              "text-align": "center",
            },
            classCell: ["text-center"],
          })
        );
      }
      const diffDays = differenceInCalendarDays(this.filter.dateEnd, this.filter.dateStart);

      for (let i = 0; i <= diffDays; i++) {
        let date = addDays(this.filter.dateStart, i);
        let classCell = {
          saturday: isSaturday(date),
          sunday: isSunday(date),
        };
        let classHeader = {
          "week-end": isSaturday(date) || isSunday(date),
          saturday: isSaturday(date),
          sunday: isSunday(date) && diffDays > 7,
        };

        columns.push(
          new Column({
            id: `data.${i}.dayType`,
            path: `data.${i}`,
            name: date.getDate(),
            useTranslate: false,
            classCell: {
              ...classCell,
              "text-center": "true",
              "p-0": "true",
              sunday: isSunday(date) && diffDays > 7,
            },
            classHeader: { ...classHeader, "text-center": "true" },
          })
        );
      }

      return columns;
    },
  },
  watch: {
    columns() {
      this.init();
    },
    filter() {
      this.init();
    },
  },
  created() {
    this.init();
    EventBus.listen(
      [
        EMPLOYEE_ABSENCE_ADDED,
        EMPLOYEE_WORK_SHIFT_ADDED,
        EMPLOYEE_OVERTIME_ADDED,
        EMPLOYEE_OVERTIME_UPDATED,
        EMPLOYEE_OVERTIME_DELETED,
      ],
      ({ employeeCode }) => {
        this.updateEmployee(employeeCode);
      }
    );
  },
  beforeDestroy() {
    EventBus.off([
      EMPLOYEE_ABSENCE_ADDED,
      EMPLOYEE_WORK_SHIFT_ADDED,
      EMPLOYEE_OVERTIME_ADDED,
      EMPLOYEE_OVERTIME_UPDATED,
      EMPLOYEE_OVERTIME_DELETED,
    ]);
  },
  methods: {
    init() {
      if (this.$isset(this.filter.dateStart) && this.$isset(this.filter.dateEnd)) {
        this.isLoading = true;
        this.rows = [];

        api
          .getPlanEmployeeTypeAll({
            from: this.filter.dateStart,
            to: this.filter.dateEnd,
            code: this.filter.employeeCode,
          })
          .then((response) => {
            this.raw = response.data;
            let rows = response.data.map((x) => new EmployeePlanData().parse(x));

            this.rows = rows;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    updateEmployee(employeeCode) {
      this.isLoading = true;
      api
        .getPlanEmployeeType({
          from: this.filter.dateStart,
          to: this.filter.dateEnd,
          code: employeeCode,
        })
        .then((response) => {
          var index = this.rows.findIndex((x) => x.employee.employeeCode === employeeCode);
          this.$set(this.rows, index, new EmployeePlanData().parse(response.data));
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    dayItemClicked(employee, data) {
      EventBus.fire(SHOW_DAY_DETAIL_MODAL, { employee, data });
    },
    addWorkShift(employee) {
      EventBus.fire(SHOW_WORK_SHIFT_ADD_MODAL, {
        employee: this.$deepCopy(employee),
      });
    },
    addOvertimeNight(employee) {
      EventBus.fire(SHOW_OVERTIME_ADD_MODAL, {
        employee: this.$deepCopy(employee),
      });
    },
    addAbsence(employee) {
      EventBus.fire(SHOW_ABSENCE_ADD_MODAL, {
        employee: this.$deepCopy(employee),
      });
    },
    testClick() {
      this.$delete(this.rows, 0);
    },
  },
};
</script>

<style lang="scss">
.week-end {
  color: #be340a;
}
.saturday {
  // border-right: 1px solid #dee2e6;
}
.sunday {
  border-right: 2px solid $color-primary !important;
}

.table {
  & > thead > tr > th {
    border-right: 1px solid #eff1f5;
    min-width: 60px;
  }

  & > tbody > tr > td {
    border-right: 1px solid #eff1f5;
  }

  & > thead > tr > :nth-child(1),
  & > thead > tr > :nth-child(2),
  & > tbody > tr > :nth-child(1),
  & > tbody > tr > :nth-child(2) {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
  }
}
</style>
