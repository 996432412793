import axios from "axios";
import { buildUrl } from "@/config";
import { formatDate } from "@/utils/functions";

export default {
  createOvertime(data) {
    return axios.post(buildUrl("api/v1/employee-overtimes"), data);
  },
  updateOvertime(employeeCode, date, isNight, data) {
    return axios.put(
      buildUrl(`api/v1/employee-overtimes/${employeeCode}/${formatDate(date)}/${isNight}`),
      data
    );
  },
  removeOvertime(employeeCode, date, isNight) {
    return axios.delete(
      buildUrl(`api/v1/employee-overtimes/${employeeCode}/${formatDate(date)}/${isNight}`)
    );
  },
};
