<template>
  <card-info-component
    :title="$t('pages.permission.confirm_waiting')"
    :value="value"
    :is-loading="isLoading"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";
import api from "@/api/appointment";

import { STATUS_APPOINTMENT_CONFIRMWAITING } from "@/pages/components";

import { Appointment } from "@/pages/components/data";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      value: 0,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;

      const { data } = await api.getFilteredAbsences({
        status: STATUS_APPOINTMENT_CONFIRMWAITING,
      });
      this.rows = data.map((x) => new Appointment().parse(x));
      this.value = this.rows.length;

      this.isLoading = false;
    },
  },
};
</script>
