import PlanWeekOrMonthTableComponent from "./PlanWeekOrMonthTableComponent.vue";
import PlanDayTableComponent from "./PlanDayTableComponent.vue";
import GeneralSearchComponent from "./GeneralSearchComponent.vue";
import PlanComponent from "./PlanComponent.vue";
import DayDetailComponent from "./DayDetailComponent.vue";

const PLANNING_SEARCH_CHANGED_EVENT = "planning-search-changed-event";

export {
  PlanWeekOrMonthTableComponent,
  PlanDayTableComponent,
  GeneralSearchComponent,
  PlanComponent,
  DayDetailComponent,
  PLANNING_SEARCH_CHANGED_EVENT,
};
