<template>
  <div v-bind="$attrs" v-on="$listeners">
    <calendar-holiday-legend
      :description="employeeData.calendarHolidayDescription"
      v-if="isCalendarHoliday"
    />
    <div v-else>&nbsp;</div>

    <div class="p-2" @click.stop="dayItemClicked">
      <absence-justified-day-legend :withTitle="false" v-if="isAbsenceJustified" />
      <absence-unjustified-day-legend :withTitle="false" v-else-if="isAbsenceUnjustified" />
      <absence-confirm-waiting-day-legend :withTitle="false" v-else-if="isAbsenceConfirmWaiting" />

      <vacations-need-meeting-day-legend :withTitle="false" v-else-if="isVacationsNeedReunion" />
      <vacation-confirm-waiting-day-legend
        :withTitle="false"
        v-else-if="isVacationsConfirmWaiting"
      />
      <vacations-approved-day-legend :withTitle="false" v-else-if="isVacationsApproved" />

      <off-day-legend :withTitle="false" v-else-if="isDayOff" />
      <sick-day-legend :withTitle="false" v-else-if="isDaySick" />
      <working-day-legend
        v-else-if="isWorking"
        :withTitle="false"
        :badge="approvedHour"
        :workShiftCode="employeeData.workShiftCode"
        :workShiftName="employeeData.workShiftDescription"
        :date="employeeData.date"
      />
    </div>
    <div class="mt-2">
      <workshift-legend
        :date="employeeData.date"
        :workShiftCode="employeeData.workShiftCode"
        :workShiftDescription="employeeData.workShiftDescription"
      />
    </div>

    <div class="row no-gutters mb-1 mt-2">
      <div class="col-6">
        <overtime-day-legend
          v-if="employeeData.overtimeDay"
          :withTitle="false"
          :badge="employeeData.overtimeDay.qtyExtra"
          @click.stop="overtimeItemClicked(employeeData.overtimeDay)"
          :overtime="employeeData.overtimeDay"
        />
      </div>
      <div class="col-6">
        <overtime-night-legend
          v-if="employeeData.overtimeNight"
          :withTitle="false"
          :badge="employeeData.overtimeNight.qtyExtra"
          @click.stop="overtimeItemClicked(employeeData.overtimeNight)"
          :overtime="employeeData.overtimeNight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  TYPE_DAY_PLAN_ABSENCE_JUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_APPROVED,
  TYPE_DAY_PLAN_VACATIONS_NEED_REUNION,
  TYPE_DAY_PLAN_DAYOFF,
  TYPE_DAY_PLAN_DAYSICK,
  TYPE_DAY_PLAN_WORKING,
} from "@/pages/responsible/planing/data";

import {
  SHOW_DAY_DETAIL_MODAL,
  SHOW_OVERTIME_EDIT_MODAL,
} from "@/pages/responsible/planing/data/events";

import {
  EmployeePlan,
  // ,Employee
} from "@/pages/components/data";

import { mapGetters } from "vuex";

import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  WorkingDayLegend,
  OvertimeDayLegend,
  OvertimeNightLegend,
  CalendarHolidayLegend,
  WorkshiftLegend,
} from "@/pages/components/day-legend";

export default {
  components: {
    AbsenceConfirmWaitingDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceJustifiedDayLegend,
    OffDayLegend,
    SickDayLegend,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    WorkingDayLegend,
    OvertimeDayLegend,
    OvertimeNightLegend,
    CalendarHolidayLegend,
    WorkshiftLegend,
  },
  props: {
    employeeData: { type: [EmployeePlan, Object], required: true },
    employee: { type: [Object], required: true },
  },
  computed: {
    isAbsenceJustified() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_ABSENCE_JUSTIFIED;
    },
    isAbsenceUnjustified() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED;
    },
    isAbsenceConfirmWaiting() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING;
    },
    isVacationsConfirmWaiting() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING;
    },
    isVacationsApproved() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_VACATIONS_APPROVED;
    },
    isVacationsNeedReunion() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_VACATIONS_NEED_REUNION;
    },
    isDayOff() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_DAYOFF;
    },
    isDaySick() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_DAYSICK;
    },
    isWorking() {
      return this.employeeData.dayType == TYPE_DAY_PLAN_WORKING;
    },
    isCalendarHoliday() {
      return this.employeeData.isCalendarHoliday;
    },
    approvedHour() {
      return this.employeeData.approvedHours;
    },
  },
  methods: {
    ...mapGetters("auth", ["showWorkshiftName"]),
    dayItemClicked() {
      let data = { employee: this.employee, data: this.employeeData };
      EventBus.fire(SHOW_DAY_DETAIL_MODAL, data);
    },
    overtimeItemClicked(overtime) {
      if (!overtime.processed) {
        EventBus.fire(SHOW_OVERTIME_EDIT_MODAL, {
          employee: this.employee,
          data: this.employeeData,
          overtime,
        });
      }
    },
  },
};
</script>

<style></style>
