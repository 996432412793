<template>
  <div class="row">
    <div class="col">
      <suc-select-component
        :form="form"
        :name="hourStartName"
        :options="hoursInterval"
        :reduce="(option) => option.value"
      />
    </div>
    <div class="col">
      <suc-select-component
        :form="form"
        :name="hourEndName"
        :options="hoursInterval"
        :reduce="(option) => option.value"
      />
    </div>
  </div>
</template>

<script>
import { SucSelectComponent, Form } from "@/components/form";
import { addMinutes, format } from "date-fns";
import { RequiredRule } from "@/components/form/data/rules";

export default {
  components: {
    SucSelectComponent,
  },
  props: {
    hourStartName: {
      type: String,
      required: true,
    },
    hourEndName: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
  },
  computed: {
    hoursInterval() {
      let hours = [];

      let startHour = new Date(0, 0, 0, 0, 0, 0);
      for (let i = 0; i <= 47; i++) {
        const date = addMinutes(startHour, 30 * i);
        hours.push({ value: date, label: format(date, "HH:mm"), id: date.getTime() });
      }

      return hours;
    },
    fieldsRules() {
      let rules = [];
      rules.push(
        new RequiredRule({
          name: this.hourStartName,
          errorMessage: this.$t("components.fields.rules.required"),
        })
      );
      rules.push(
        new RequiredRule({
          name: this.hourEndName,
          errorMessage: this.$t("components.fields.rules.required"),
        })
      );
      return rules;
    },
  },
};
</script>
