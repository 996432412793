<template>
  <div>
    <div class="row">
      <div class="col">
        <span class="search-header">{{ $t("pages.payroll.searching_header") }}</span>
        <img src="@/assets/IC_Minimise.png" alt="min" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <form>
          <div class="form-row">
            <div class="col-md-6">
              <employee-list-field id="employee" name="employee" :form="form" />
            </div>
            <div class="col-md-3" v-if="!isDay">
              <month-field :form="form" name="month" />
            </div>
            <div class="col-md-3" v-if="!isDay">
              <year-field :form="form" name="year" />
            </div>
            <div class="offset-md-1 col-md-2" v-if="isDay">
              <day-field id="date" :form="form" name="day" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <a href="#" class="mx-4 search-clean" @click.prevent="clear">{{
          $t("components.actions.clear")
        }}</a>
        <vue-button
          :title="$t('components.actions.search')"
          class="btn-primary search-button"
          @click="seachClicked"
        ></vue-button>
      </div>
    </div>
  </div>
</template>

<script>
import { EmployeeListField, YearField, MonthField, DayField } from "@/pages/components/fields";
import { VueButton, Form, Options } from "@/components/form";

import {
  SUC_SELECT_CHANGED_EVENT,
  SUC_PERIOD_CHANGED_EVENT,
  DATE_PERIOD_DAY,
} from "@/components/form";
import { PLANNING_SEARCH_CHANGED_EVENT } from "./index";

export default {
  components: {
    EmployeeListField,
    YearField,
    MonthField,
    VueButton,
    DayField,
  },
  data() {
    return {
      date: new Date(),
      type: null,
      form: new Form({
        data: {
          day: null,
          month: null,
          year: null,
          employee: null,
        },
        options: {
          ...new Options().add("employee", "employeeCode"),
          ...new Options().add("month", "id"),
        },
      }),
    };
  },
  computed: {
    selectChangeEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
    isDay() {
      return this.type == DATE_PERIOD_DAY;
    },
  },
  created() {
    EventBus.listen(SUC_PERIOD_CHANGED_EVENT, (args) => {
      this.type = args.type;
    });
  },
  beforeDestroy() {
    EventBus.off(SUC_PERIOD_CHANGED_EVENT);
  },
  methods: {
    init() {},
    clear() {
      this.form.reset();
      EventBus.fire(PLANNING_SEARCH_CHANGED_EVENT, { date: this.formDate({}), type: this.type });
    },
    seachClicked() {
      let data = this.form.data();
      let payload = { date: this.formDate(data), type: this.type, ...data };
      EventBus.fire(PLANNING_SEARCH_CHANGED_EVENT, payload);
    },
    formDate(data) {
      let date = new Date();
      if (this.isDay && this.$isset(data.day)) {
        date = this.$parseDate(data.day);
      } else if (this.$isset(data.month) && this.$isset(data.year)) {
        date = new Date(data.year, data.month, 1);
      }
      return date;
    },
  },
};
</script>

<style lang="scss" scoped>
.payroll-action {
  padding-left: 5px;
  color: $color-primary;

  & > img {
    margin-right: 5px;
  }
}

.payroll-searching {
  .search-header {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-right: 5px;
  }

  .search-button {
    border-radius: $border-radius;
    height: 50px;
    width: 125px;
  }

  .search-clean {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
}
</style>
