<template>
  <vue-modal
    :modal-title="$t('pages.planing-team.general.day_detail')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modalExtraLarge="true"
    :loading-primary-footer-button="isLoading"
    @[closeEvent]="onCloseButtonClicked"
    :showSecondaryFooterButton="false"
    :showPrimaryFooterButton="false"
  >
    <template #body>
      <div class="row mb-4">
        <div class="col">
          <div class="card box-shadow employee-data">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-9">
                  <suc-text-form-show-field
                    :title="$t('pages.planing-team-validate.employee_name')"
                    :value="employeeName"
                    class="m-3"
                  />
                </div>
                <div class="col-md-3">
                  <suc-text-form-show-field
                    :title="$t('pages.planing-team-validate.employee_id')"
                    :value="employeeCode"
                    class="m-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col text-right"><formatted-date-component :val="currentDate" /></div>
      </div>

      <vue-data-table
        :classTable="classTable"
        :table-columns="columns"
        :table-rows="rows"
        :is-loading="isLoading"
        :showRefreshButton="false"
        :showRowTableAction="false"
        :empty-rows-message="$t('components.table.no_data')"
      >
        <template #table-row="props">
          <template v-if="props.cell.getColumn.id == 'employee.holidaysRemaining'">
            {{ props.cell.getValue }}
          </template>

          <template v-else-if="props.cell.getColumn.id == 'employee.hourBalance'">
            {{ props.cell.getValue }}
          </template>

          <template v-else-if="!['actions'].includes(props.cell.getColumn.id)">
            <plan-day-item-table-component :cellData="props.cell" />
          </template>
        </template>

        <template #description>
          <div class="row my-2">
            <div class="col">
              <hour-planned-legend class="m-1" :rounded="true" style="width: 170px" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col d-flex flex-wrap">
              <absence-justified-day-legend class="m-1" />
              <absence-unjustified-day-legend class="m-1" />
              <absence-confirm-waiting-day-legend class="m-1" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col d-flex flex-wrap">
              <vacations-approved-day-legend class="m-1" />
              <vacation-confirm-waiting-day-legend class="m-1" />
              <vacations-need-meeting-day-legend class="m-1" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col d-flex flex-wrap">
              <off-day-legend class="m-1" />
              <sick-day-legend class="m-1" />
            </div>
          </div>
        </template>
      </vue-data-table>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, { CLOSE_BUTTON_CLICKED_MODAL_EVENT } from "@/components/modal";
import VueDataTable, { Column } from "@/components/table";
import { FormattedDateComponent } from "@/components/formatted";
import { SucTextFormShowField } from "@/components/form";

import { SHOW_DAY_DETAIL_MODAL } from "@/pages/responsible/planing/data/events";
import PlanDayItemTableComponent from "@/pages/responsible/planing/general/components/PlanDayItemTableComponent.vue";

import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  HourPlannedLegend,
} from "@/pages/components/day-legend";

export default {
  components: {
    VueModal,
    VueDataTable,
    AbsenceConfirmWaitingDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceJustifiedDayLegend,
    OffDayLegend,
    SickDayLegend,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    HourPlannedLegend,
    PlanDayItemTableComponent,
    FormattedDateComponent,
    SucTextFormShowField,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      employee: null,
      detail: null,
      startHour: 0,
      endHour: 0,
      classTable: ["table", "day-table"],
      rows: [],
    };
  },
  created() {
    EventBus.listen(SHOW_DAY_DETAIL_MODAL, (args) => {
      this.employee = args.employee;
      this.detail = args.data;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;

        this.init();

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_DAY_DETAIL_MODAL);
  },
  computed: {
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    employeeName() {
      return this.$isset(this.employee) ? this.employee.fullName : "";
    },
    employeeCode() {
      return this.$isset(this.employee) ? this.employee.employeeCode : "";
    },
    currentDate() {
      return this.$isset(this.detail) ? this.detail.date : null;
    },
    columns() {
      let columns = [];

      columns.push(
        new Column({
          id: "employee.holidaysRemaining",
          name: "pages.planing-team.general.holidaysRemainingShort",
          styleHeader: {
            "text-align": "center",
          },
          classCell: ["text-center"],
        })
      );
      columns.push(
        new Column({
          id: "employee.hourBalance",
          name: "pages.planing-team.general.hourBalanceShort",
          styleHeader: {
            "text-align": "center",
          },
          classCell: ["text-center"],
        })
      );

      for (let i = this.startHour; i <= this.endHour; i++) {
        columns.push(
          new Column({
            id: `plan.dayType`,
            name: `${i}h`,
            meta: {
              hour: i,
              startHour: this.startHour,
              endHour: this.endHour,
            },
            useTranslate: false,
            styleCell: {
              padding: 0,
            },
            classHeader: { "text-center": true },
            classCell: ["text-center", "align-middle"],
          })
        );
      }

      return columns;
    },
  },
  methods: {
    onCloseButtonClicked() {
      this.showModal = false;
    },
    init() {
      this.isLoading = true;
      this.rows = [];

      this.startHour = this.compareStartingHour(
        this.detail.firstPartStartingTime,
        this.detail.secondPartStartingTime,
        this.startHour
      );

      this.endHour = this.compareEndHour(
        this.detail.firstPartEndingTime,
        this.detail.secondPartEndingTime,
        this.endHour
      );

      this.rows = [
        {
          employee: this.employee,
          plan: this.detail,
        },
      ];
      this.isLoading = false;
    },
    compareStartingHour(date1, date2, current) {
      let start = this.$isset(date1) ? date1.getHours() : 0;
      let end = this.$isset(date2) ? date2.getHours() : 0;

      let min = Math.min(start, end);
      if (min > 0) {
        return current > 0 ? Math.min(current, min) : min;
      }
      return current;
    },

    compareEndHour(date1, date2, current) {
      let start = this.$isset(date1) ? date1.getHours() : 0;
      let end = this.$isset(date2) ? date2.getHours() : 0;

      let max = Math.max(start, end);
      if (max > 0) {
        return current > 0 ? Math.max(current, max) : max;
      }
      return current;
    },
  },
};
</script>
