<template>
  <main role="main" class="container-fluid">
    <article>
      <div class="container">
        <h1 class="mb-4">{{ $t("pages.planing-team.title") }}</h1>

        <div class="d-flex mb-4 p-2 table-responsive">
          <employee-working-widget />
          <employee-vacations-widget class="ml-4" />
          <employee-sick-widget class="ml-4" />
          <employee-resting-widget class="ml-4" />
          <confirm-waiting-absence-widget class="ml-4" />
        </div>

        <card-data-component
          :tabs="tabs"
          :currentTabKey="tabKey"
          class="mb-4"
          @[tabChangedEvent]="tabChanged"
        >
          <template #data="props">
            <div v-if="isGeneralTab(props.tab.key)">
              <general-search-component class="mb-4" />
            </div>
            <div v-if="isAbsenseTab(props.tab.key)">
              <absence-list-search-component class="mb-4" />
            </div>
          </template>
        </card-data-component>
      </div>

      <plan-component v-if="isGeneralTab(tabKey)" />

      <work-shift-list-component v-if="isWorkShiftTab(tabKey)" />
      <absences-list-component v-if="isAbsenseTab(tabKey)" />
      <work-shift-detail-modal />
    </article>
  </main>
</template>

<script>
import CardDataComponent, { Tab, CARD_DATA_TAB_CHANGED } from "@/pages/components/card-data";
import {
  GeneralSearchComponent,
  PlanComponent,
} from "@/pages/responsible/planing/general/components";

import { WorkShiftListComponent } from "@/pages/responsible/planing/workshift/components";
import {
  AbsencesListComponent,
  AbsenceListSearchComponent,
} from "@/pages/responsible/planing/absences/components";

import {
  PLANING_TEAM_GENERAL_TAB,
  PLANING_TEAM_WORK_SHIFT_TAB,
  PLANING_TEAM_ABSENSE_TAB,
} from "@/pages/responsible/responsible-constants";
import WorkShiftDetailModal from "@/pages/responsible/planing/workshift/components/WorkShiftDetailModal.vue";

import {
  EmployeeWorkingWidget,
  EmployeeRestingWidget,
  EmployeeVacationsWidget,
  EmployeeSickWidget,
} from "./widgets";

import { ConfirmWaitingAbsenceWidget } from "@/pages/responsible/planing/absences/widgets";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  layout: "MainLayout",
  components: {
    EmployeeWorkingWidget,
    EmployeeRestingWidget,
    EmployeeVacationsWidget,
    EmployeeSickWidget,
    CardDataComponent,
    GeneralSearchComponent,
    PlanComponent,
    WorkShiftListComponent,
    AbsencesListComponent,
    AbsenceListSearchComponent,
    ConfirmWaitingAbsenceWidget,
    WorkShiftDetailModal,
  },
  middleware: ["auth", "notification"],
  data() {
    return {
      isLoading: false,
      tabs: [
        new Tab({
          name: this.$t("pages.planing-team.general.title"),
          key: PLANING_TEAM_GENERAL_TAB,
        }),
        new Tab({
          name: this.$t("pages.planing-team.absences.title"),
          key: PLANING_TEAM_ABSENSE_TAB,
        }),
        new Tab({
          name: this.$t("pages.planing-team.workshift.title"),
          key: PLANING_TEAM_WORK_SHIFT_TAB,
        }),
      ],
      month: [],
      year: [],
      employeeCode: null,
      tabKey: "",
    };
  },
  computed: {
    tabChangedEvent() {
      return CARD_DATA_TAB_CHANGED;
    },
  },
  mounted() {
    if (window.location.hash) {
      this.tabKey = window.location.hash.replace("#", "");
    } else {
      this.tabKey = PLANING_TEAM_GENERAL_TAB;
    }
  },
  methods: {
    tabChanged(tab) {
      this.tabKey = tab.key;
    },
    isGeneralTab(tabKey) {
      return tabKey === PLANING_TEAM_GENERAL_TAB;
    },
    isWorkShiftTab(tabKey) {
      return tabKey === PLANING_TEAM_WORK_SHIFT_TAB;
    },
    isAbsenseTab(tabKey) {
      return tabKey === PLANING_TEAM_ABSENSE_TAB;
    },
  },
};
</script>

<style lang="scss" scoped></style>
