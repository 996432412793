<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.add_absence')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <form class="permission-form mt-3">
        <div class="row mb-4" v-if="employeeInitialized">
          <div class="col">
            <h6>
              {{ $t("pages.planing-team-validate.employee_name") }}:
              {{ employee.fullName }}
            </h6>
          </div>
        </div>

        <suc-form-has-error :form="form" />

        <div class="row">
          <div class="col">
            <absence-type-field
              id="absence-type-field"
              name="typeAppointment"
              :form="form"
              :required="true"
              :withoutVacation="false"
            />
          </div>
        </div>

        <div class="row" v-if="!absenceTypeRecognized">
          <div class="col" v-if="form.typeAppointment">
            <span class="text-danger">{{
              $t("pages.permission.absences.error_absence_configuration")
            }}</span>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <absence-start-day-field
                id="absence-start-date"
                name="dateStart"
                :form="form"
                :required="true"
                v-if="absenceTypeDay"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <absence-end-day-field
                id="absence-end-date"
                name="dateEnd"
                :form="form"
                :required="true"
                v-if="absenceTypeDay"
              />
            </div>
            <div class="col-sm-12 col-md-5">
              <absence-day-field
                id="absence-end-date"
                name="day"
                :form="form"
                :required="true"
                v-if="absenceTypeHour"
              />
            </div>
          </div>

          <div class="row" v-if="absenceTypeHour">
            <div class="col" v-if="absenceFromWorkShift">
              <absence-hour-for-full-shift-field
                hourTypeName="hourType"
                hourStartName="hourStart"
                hourEndName="hourEnd"
                :form="form"
                :required="true"
                :workShiftHour="workShiftHour"
                v-if="$isset(workShiftHour)"
                ref="hourForFullShift"
              />
            </div>
            <div class="col" v-else>
              <absence-hour-with-full-day-hours
                hourTypeName="hourType"
                hourStartName="hourStart"
                hourEndName="hourEnd"
                :form="form"
                :required="true"
                ref="hourForFullDay"
                v-if="$isset(workShiftHour)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <absence-file-field id="absence-type" name="attachment" :form="form" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <absence-comment-field id="absence-comment" name="comments" :form="form" />
            </div>
          </div>
        </div>
      </form>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { ModalAlert } from "@/components/modal";
import {
  SHOW_ABSENCE_ADD_MODAL,
  EMPLOYEE_ABSENCE_ADDED,
} from "@/pages/responsible/planing/data/events";
import {
  SucFormHasError,
  Form,
  FORM_ERRORS,
  Options,
  SUC_SELECT_CHANGED_EVENT,
} from "@/components/form";

import { RequiredRule, FormRule } from "@/components/form/data/rules";
import { RevisionAppointment } from "@/pages/components/data";
import { STATUS_APPOINTMENT_APPROVED } from "@/pages/components";

import {
  AbsenceStartDayField,
  AbsenceEndDayField,
  AbsenceCommentField,
  AbsenceTypeField,
  AbsenceHourForFullShiftField,
  AbsenceHourWithFullDayHours,
  AbsenceDayField,
  AbsenceFileField,
} from "@/pages/employee/permission/absences/fields";

import { mapGetters } from "vuex";
import apiAppointment from "@/api/appointment";
import apiPlan from "@/api/plan";

import { WorkShiftHour } from "@/pages/responsible/planing/workshift/data";
import { isAfter, isEqual, getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { ABSENCE_TYPE_UNIT_DAY, ABSENCE_TYPE_UNIT_HOUR } from "@/pages/components/constants";

export default {
  components: {
    VueModal,
    AbsenceStartDayField,
    AbsenceEndDayField,
    AbsenceCommentField,
    AbsenceTypeField,
    AbsenceDayField,
    AbsenceHourForFullShiftField,
    AbsenceHourWithFullDayHours,
    AbsenceFileField,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      employee: null,
      form: new Form({
        data: {
          day: null,
          dateStart: null,
          dateEnd: null,
          comments: null,
          typeAppointment: null,
          hourType: null,
          hourStart: null,
          hourEnd: null,
          attachment: null,
        },
        options: new Options().add("typeAppointment", "code"),
      }),
      workShiftHour: null,
    };
  },
  computed: {
    employeeInitialized() {
      return this.$isset(this.employee);
    },
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    ...mapGetters("auth", ["absenceFromWorkShift"]),
    absenceTypeDay() {
      if (this.$isset(this.form.typeAppointment)) {
        if (this.form.typeAppointment.unitOfMeasureCode === ABSENCE_TYPE_UNIT_DAY) {
          return true;
        }
      }
      return false;
    },
    absenceTypeHour() {
      if (this.$isset(this.form.typeAppointment)) {
        if (this.form.typeAppointment.unitOfMeasureCode === ABSENCE_TYPE_UNIT_HOUR) {
          return true;
        }
      }
      return false;
    },
    absenceTypeRecognized() {
      if (this.$isset(this.form.typeAppointment)) {
        if (
          [ABSENCE_TYPE_UNIT_DAY, ABSENCE_TYPE_UNIT_HOUR].includes(
            this.form.typeAppointment.unitOfMeasureCode
          )
        ) {
          return true;
        }
      }
      return false;
    },
    fieldsRules() {
      let rules = [
        new RequiredRule({
          name: "typeAppointment",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];

      if (this.absenceTypeHour) {
        rules.push(
          new RequiredRule({
            name: "day",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.absenceTypeDay) {
        rules.push(
          new RequiredRule({
            name: "dateStart",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
        rules.push(
          new RequiredRule({
            name: "dateEnd",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      return rules;
    },
    formRules() {
      let rules = [];
      if (this.absenceTypeDay) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return isAfter(x.dateEnd, x.dateStart) || isEqual(x.dateEnd, x.dateStart);
            },
            errorMessage: this.$t("pages.permission.wrong_period_date"),
          })
        );
      }

      if (this.absenceTypeHour) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return isAfter(x.hourEnd, x.hourStart);
            },
            errorMessage: this.$t("pages.permission.wrong_hours"),
          })
        );
      }

      return rules;
    },
    selectChangedEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
  },
  created() {
    EventBus.listen(SHOW_ABSENCE_ADD_MODAL, ({ employee }) => {
      this.employee = employee;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.clear();
      }
    },
    "form.day"(val) {
      if (this.$isset(val)) {
        this.getMyWorkShiftHourByDate();
      } else {
        this.workShiftHour = null;
      }
    },
    "form.typeAppointment"() {
      this.form.day = null;
      this.form.dateStart = null;
      this.form.dateEnd = null;
      this.form.comments = null;
      this.form.hourType = null;
      this.form.hourStart = null;
      this.form.hourEnd = null;
      this.form.attachment = null;
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_ABSENCE_ADD_MODAL);
  },
  methods: {
    async onPrimaryButtonClicked() {
      let fieldsRules = [].concat(this.fieldsRules);
      let formRules = [];
      if (this.absenceTypeHour) {
        if (this.absenceFromWorkShift) {
          fieldsRules = fieldsRules.concat(this.$refs.hourForFullShift.fieldsRules);
        } else {
          fieldsRules = fieldsRules.concat(this.$refs.hourForFullDay.fieldsRules);
        }
      }
      formRules = [].concat(this.formRules);
      this.form.setFieldsRules(fieldsRules);
      this.form.setFormRules(formRules);

      //get
      if (this.form.validate()) {
        let data = this.prepareData();

        this.isLoading = true;
        apiAppointment
          .createAppointment(data)
          .then((response) => {
            const appointment = response.data;
            const revision = new RevisionAppointment({
              id: appointment.id,
              status: STATUS_APPOINTMENT_APPROVED,
              message: this.$isset(this.form.comments) ? this.form.comments : "",
            });
            apiAppointment
              .revisionAppointment({ revision })
              .then(() => {
                this.clear();
                this.showModal = false;
                this.$emit("changed");
                EventBus.fire(EMPLOYEE_ABSENCE_ADDED, { employeeCode: this.employee.employeeCode });
              })
              .catch((error) => {
                console.error(error.response);
                ModalAlert.error({
                  message: this.$t("pages.permission.absences.error_confirmation"),
                });
              });
          })
          .catch((error) => {
            this.form.errors.set(FORM_ERRORS, this.$t("components.messages.error"));

            console.error(error.response.data);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    typeAppointmentChanged(val) {
      this.form.typeAppointment = this.$isset(val) ? val.code : null;
    },
    prepareData() {
      let data = this.form.data();
      let request = new FormData();
      request.append("employeeCode", this.employee.employeeCode);
      request.append("typeAppointment", data.typeAppointment);

      if (this.absenceTypeHour) {
        let startHour = getHours(this.form.hourStart);
        let startMinut = getMinutes(this.form.hourStart);
        let endHour = getHours(this.form.hourEnd);
        let endMinut = getMinutes(this.form.hourEnd);

        let dateStart = setMinutes(setHours(this.$parseDate(data.day), startHour), startMinut);
        let dateEnd = setMinutes(setHours(this.$parseDate(data.day), endHour), endMinut);

        request.append("dateEnd", this.form.formatDate(dateEnd));
        request.append("dateStart", this.form.formatDate(dateStart));
      } else {
        request.append("dateEnd", data.dateEnd);
        request.append("dateStart", data.dateStart);
      }

      if (this.$isset(data.attachment)) {
        request.append("attachment", data.attachment);
      }

      return request;
    },
    getMyWorkShiftHourByDate() {
      this.isLoading = true;
      apiPlan
        .getWorkShiftHourByDate({ code: this.employee.employeeCode, day: this.form.day })
        .then((response) => {
          this.workShiftHour = new WorkShiftHour().parse(response.data);
          this.form.errors.clear();
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 404) {
            this.form.day = null;
            this.form.errors.set(FORM_ERRORS, this.$t("pages.permission.cant_use_date"));
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.form.errors.clear();
      this.form.reset();
    },
  },
};
</script>
