<template>
  <div>
    <suc-period-component @[periodChangedEvent]="init" :defaultType="defaultType" class="mb-2" />

    <plan-day-table-component :filter="dayFilter" v-if="isDay" />
    <plan-week-or-month-table-component :filter="weekOrMonthFilter" v-else />

    <add-work-shift-modal />
    <add-absence-modal />
    <add-overtime-modal />
    <edit-overtime-modal />
  </div>
</template>

<script>
import {
  SucPeriodComponent,
  SUC_PERIOD_CHANGED_EVENT,
  SUC_DATE_PERIOD_SET_DATE_EVENT,
  DATE_PERIOD_WEEK,
  DATE_PERIOD_MONTH,
  DATE_PERIOD_DAY,
} from "@/components/form";

import {
  PLANNING_SEARCH_CHANGED_EVENT,
  PlanWeekOrMonthTableComponent,
  PlanDayTableComponent,
} from "./index";

import { PlanWeekOMonthFilter, PlanDayFilter } from "@/pages/responsible/planing/general/data";

import AddWorkShiftModal from "@/pages/responsible/planing/validate/AddWorkShiftModal.vue";
import AddAbsenceModal from "@/pages/responsible/planing/validate/AddAbsenceModal.vue";
import AddOvertimeModal from "@/pages/responsible/planing/validate/AddOvertimeModal.vue";
import EditOvertimeModal from "@/pages/responsible/planing/validate/EditOvertimeModal.vue";

export default {
  components: {
    SucPeriodComponent,
    PlanWeekOrMonthTableComponent,
    PlanDayTableComponent,
    AddWorkShiftModal,
    AddAbsenceModal,
    AddOvertimeModal,
    EditOvertimeModal,
  },
  props: {
    defaultType: {
      type: String,
      validator: function (val) {
        const types = [DATE_PERIOD_DAY, DATE_PERIOD_WEEK, DATE_PERIOD_MONTH];
        if (!types.includes(val)) {
          console.error("type shoud be one of", types);
          return false;
        }
        return true;
      },
      default() {
        return DATE_PERIOD_WEEK;
      },
    },
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      employeeCode: null,
      type: null,
      isLoading: false,
    };
  },
  computed: {
    periodChangedEvent() {
      return SUC_PERIOD_CHANGED_EVENT;
    },
    isDay() {
      return this.type == DATE_PERIOD_DAY;
    },
    weekOrMonthFilter() {
      return new PlanWeekOMonthFilter({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        employeeCode: this.employeeCode,
      });
    },
    dayFilter() {
      return new PlanDayFilter({
        date: this.dateStart,
        employeeCode: this.employeeCode,
      });
    },
  },
  created() {
    EventBus.listen(PLANNING_SEARCH_CHANGED_EVENT, (args) => {
      this.employeeCode = args.employee;
      EventBus.fire(SUC_DATE_PERIOD_SET_DATE_EVENT, { date: args.date });
    });
  },
  beforeDestroy() {
    EventBus.off(PLANNING_SEARCH_CHANGED_EVENT);
  },

  methods: {
    init({ start, end, type }) {
      this.dateStart = start;
      this.dateEnd = end;
      this.type = type;
    },
  },
};
</script>

<style></style>
