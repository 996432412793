import AbsencesListComponent from "./AbsencesListComponent.vue";
import AbsenceListSearchComponent from "./AbsenceListSearchComponent.vue";
import RevisionModal from "./RevisionModal.vue";

const ABSENCES_SEARCH_CHANGED_EVENT = "absences-search-changed-event";

export {
  AbsencesListComponent,
  AbsenceListSearchComponent,
  RevisionModal,
  ABSENCES_SEARCH_CHANGED_EVENT,
};
