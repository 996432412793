<template>
  <div>
    <suc-radio-field-component
      :title="$t('pages.permission.absences.hours')"
      v-bind="$attrs"
      :form="form"
      :name="hourTypeName"
      :options="options"
      @[changedHourTypeEvent]="changedHourType"
    ></suc-radio-field-component>
    <div class="row" v-if="hasHourCustom && formOptionCustom">
      <div class="col">
        <suc-select-component
          :form="form"
          :name="hourStartName"
          :options="customHours"
          :reduce="(option) => option.value"
        />
      </div>
      <div class="col">
        <suc-select-component
          :form="form"
          :name="hourEndName"
          :options="customHours"
          :reduce="(option) => option.value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SucRadioFieldComponent,
  SucSelectComponent,
  RadioOption,
  Form,
  SUC_RADIO_CHANGED_EVENT,
} from "@/components/form";
import { RequiredRule } from "@/components/form/data/rules";

import { WorkShiftHour } from "@/pages/responsible/planing/workshift/data";

import { differenceInMinutes, addMinutes, format } from "date-fns";

export default {
  components: {
    SucRadioFieldComponent,
    SucSelectComponent,
  },
  props: {
    workShiftHour: WorkShiftHour,
    hourTypeName: {
      type: String,
      required: true,
    },
    hourStartName: {
      type: String,
      required: true,
    },
    hourEndName: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
  },
  computed: {
    changedHourTypeEvent() {
      return SUC_RADIO_CHANGED_EVENT;
    },
    options() {
      let options = [new RadioOption({ name: this.$t("pages.permission.all_day"), value: 0 })];
      if (this.hasWorkShiftHourMorningEvening) {
        options.push(new RadioOption({ name: this.$t("pages.permission.morning"), value: 1 }));
        options.push(new RadioOption({ name: this.$t("pages.permission.evenning"), value: 2 }));
      } else {
        options.push(new RadioOption({ name: this.$t("pages.permission.custom"), value: 3 }));
      }
      return options;
    },
    /**
     * need generate hours to select manually
     */
    formOptionCustom() {
      return this.$isset(this.form[this.hourTypeName]) ? this.form[this.hourTypeName] == 3 : false;
    },
    hasWorkShiftHourMorning() {
      return (
        this.$isset(this.workShiftHour) &&
        this.$isset(this.workShiftHour.firstPartStartingTime) &&
        this.$isset(this.workShiftHour.firstPartEndingTime)
      );
    },
    hasWorkShiftHourEvening() {
      return (
        this.$isset(this.workShiftHour) &&
        this.$isset(this.workShiftHour.secondPartStartingTime) &&
        this.$isset(this.workShiftHour.secondPartEndingTime)
      );
    },
    hasWorkShiftHourMorningEvening() {
      return this.hasWorkShiftHourMorning && this.hasWorkShiftHourEvening;
    },
    hasHourCustom() {
      return this.hasWorkShiftHourMorning && !this.hasWorkShiftHourEvening;
    },
    customHours() {
      let hours = [];
      if (this.hasWorkShiftHourMorning) {
        let max =
          differenceInMinutes(
            this.workShiftHour.firstPartEndingTime,
            this.workShiftHour.firstPartStartingTime
          ) / 30;
        for (let i = 0; i <= max; i++) {
          const date = addMinutes(this.workShiftHour.firstPartStartingTime, 30 * i);
          hours.push({ value: date, label: format(date, "HH:mm"), id: date.getTime() });
        }
      }
      return hours;
    },
    fieldsRules() {
      let rules = [];
      rules.push(
        new RequiredRule({
          name: this.hourTypeName,
          errorMessage: this.$t("components.fields.rules.required"),
        })
      );

      //custom hours
      if (this.formOptionCustom) {
        rules.push(
          new RequiredRule({
            name: this.hourStartName,
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
        rules.push(
          new RequiredRule({
            name: this.hourEndName,
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }
      return rules;
    },
  },
  methods: {
    changedHourType(val) {
      switch (parseInt(val)) {
        case 1:
          //morning hours
          this.form[this.hourStartName] = this.workShiftHour.firstPartStartingTime;
          this.form[this.hourEndName] = this.workShiftHour.firstPartEndingTime;
          break;
        case 2:
          //evening
          this.form[this.hourStartName] = this.workShiftHour.secondPartStartingTime;
          this.form[this.hourEndName] = this.workShiftHour.secondPartEndingTime;
          break;
        case 3:
          //custom hours
          this.form[this.hourStartName] = null;
          this.form[this.hourEndName] = null;
          break;
        default:
          //all day
          this.form[this.hourStartName] = new Date(0, 0, 0, 0, 0);
          this.form[this.hourEndName] = new Date(0, 0, 0, 0, 0);
      }
    },
  },
};
</script>
