<template>
  <div>
    <vue-data-table
      :classTable="classTable"
      :table-columns="columns"
      :table-rows="rows"
      :is-loading="isLoading"
      :stickyTable="true"
      :empty-rows-message="$t('components.table.no_data')"
      @[needDataUpdateEvent]="init"
    >
      <template #table-row="props">
        <template v-if="props.cell.getColumn.id == 'employee.holidaysRemaining'">
          {{ props.cell.getValue }}
        </template>

        <template v-else-if="props.cell.getColumn.id == 'employee.hourBalance'">
          {{ props.cell.getValue }}
        </template>

        <template v-else-if="props.cell.getColumn.id == 'plan.workShiftDescription'">
          <workshift-legend
            :date="props.cell.getRaw.plan.date"
            :workShiftCode="props.cell.getRaw.plan.workShiftCode"
            :workShiftDescription="props.cell.getRaw.plan.workShiftDescription"
          />
        </template>

        <template v-else-if="props.cell.getColumn.id == 'employee.fullName'">
          <div>
            {{ props.cell.getValue }}
          </div>
          <div>
            <span class="badge badge-pill badge-light pl-0"
              >{{ props.cell.getRaw.employee.employeeCode }}
            </span>
          </div>
        </template>

        <template v-else-if="!['actions'].includes(props.cell.getColumn.id)">
          <plan-day-item-table-component :cellData="props.cell" />
        </template>
      </template>

      <template #table-action-menu="props">
        <router-link
          :to="{
            name: 'planing-team-validate',
            params: { code: props.row.raw.employee.employeeCode },
          }"
          class="dropdown-item"
        >
          {{ $t("pages.permission.validate_hours") }}
        </router-link>
        <!-- <a class="dropdown-item" @click.prevent="addAbsence(props.row.raw.employee)">
          {{ $t("pages.planing-team-validate.add_absence") }}
        </a>
        <a class="dropdown-item" @click.prevent="addWorkShift(props.row.raw.employee)">
          {{ $t("pages.planing-team-validate.add_workshift") }}
        </a> -->
        <router-link
          :to="{
            name: 'profile-employee',
            params: { code: props.row.raw.employee.employeeCode },
          }"
          class="dropdown-item"
        >
          {{ $t("pages.profile.tabs.personal_data.title") }}
        </router-link>
      </template>

      <template #description>
        <div class="row my-2">
          <div class="col">
            <hour-planned-legend class="m-1" :rounded="true" style="width: 170px" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <absence-justified-day-legend class="m-1" />
            <absence-unjustified-day-legend class="m-1" />
            <absence-confirm-waiting-day-legend class="m-1" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <vacations-approved-day-legend class="m-1" />
            <vacation-confirm-waiting-day-legend class="m-1" />
            <vacations-need-meeting-day-legend class="m-1" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col d-flex flex-wrap">
            <off-day-legend class="m-1" />
            <sick-day-legend class="m-1" />
          </div>
        </div>
      </template>
    </vue-data-table>
  </div>
</template>

<script>
import api from "@/api/plan";
import VueDataTable, { Column, VUE_DATA_TABLE_NEED_DATA_UPDATE } from "@/components/table";
import PlanDayItemTableComponent from "@/pages/responsible/planing/general/components/PlanDayItemTableComponent.vue";
import { Employee, EmployeePlan } from "@/pages/components/data";

import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  HourPlannedLegend,
  WorkshiftLegend,
} from "@/pages/components/day-legend";

import { PlanDayFilter } from "@/pages/responsible/planing/general/data";

import { mapGetters } from "vuex";

import {
  SHOW_ABSENCE_ADD_MODAL,
  SHOW_WORK_SHIFT_ADD_MODAL,
  EMPLOYEE_ABSENCE_ADDED,
  EMPLOYEE_WORK_SHIFT_ADDED,
} from "@/pages/responsible/planing/data/events";

export default {
  components: {
    VueDataTable,
    PlanDayItemTableComponent,
    AbsenceConfirmWaitingDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceJustifiedDayLegend,
    OffDayLegend,
    SickDayLegend,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    HourPlannedLegend,
    WorkshiftLegend,
  },
  props: {
    filter: {
      type: PlanDayFilter,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      raw: [],
      isLoading: false,
      startHour: 0,
      endHour: 0,
      classTable: ["table", "day-table"],
    };
  },
  watch: {
    filter() {
      this.init();
    },
  },
  created() {
    this.init();
    EventBus.listen([EMPLOYEE_ABSENCE_ADDED, EMPLOYEE_WORK_SHIFT_ADDED], ({ employeeCode }) => {
      this.updateEmployee(employeeCode);
    });
  },
  beforeDestroy() {
    EventBus.off([EMPLOYEE_ABSENCE_ADDED, EMPLOYEE_WORK_SHIFT_ADDED]);
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack", "showWorkshiftName"]),
    needDataUpdateEvent() {
      return VUE_DATA_TABLE_NEED_DATA_UPDATE;
    },
    columns() {
      let columns = [];

      columns.push(
        new Column({
          id: "employee.fullName",
          name: "pages.planing-team.general.employee",
          styleHeader: {
            width: "300px !important",
            "max-width": "300px !important",
            "min-width": "300px !important",
            "text-align": "center",
          },
          styleCell: {
            width: "300px !important",
            "min-width": "300px !important",
            "max-width": "300px !important",
            "text-align": "left",
          },
          classCell: {},
        })
      );
      columns.push(
        new Column({
          id: "employee.holidaysRemaining",
          name: "pages.planing-team.general.holidaysRemainingShort",
          styleHeader: {
            "text-align": "center",
          },
          classCell: ["text-center"],
        })
      );
      if (this.activeHoursPack) {
        columns.push(
          new Column({
            id: "employee.hourBalance",
            name: "pages.planing-team.general.hourBalanceShort",
            styleHeader: {
              "text-align": "center",
            },
            classCell: ["text-center"],
          })
        );
      }
      if (this.showWorkshiftName) {
        columns.push(
          new Column({
            id: "plan.workShiftDescription",
            name: "pages.planing-team.general.workShiftName",
            styleHeader: {
              "text-align": "center",
            },
            classCell: ["text-center"],
          })
        );
      }

      for (let i = this.startHour; i <= this.endHour; i++) {
        columns.push(
          new Column({
            id: `plan.dayType`,
            name: `${i}h`,
            meta: {
              hour: i,
              startHour: this.startHour,
              endHour: this.endHour,
            },
            useTranslate: false,
            styleCell: {
              padding: 0,
            },
            classHeader: { "text-center": true },
            classCell: ["text-center", "align-middle"],
          })
        );
      }

      return columns;
    },
  },
  methods: {
    init() {
      if (this.$isset(this.filter.date)) {
        this.isLoading = true;
        this.rows = [];

        api
          .getPlanEmployeeTypeAll({
            from: this.filter.date,
            to: this.filter.date,
            code: this.filter.employeeCode,
          })
          .then((response) => {
            this.raw = response.data;

            let items = response.data.map((x) => {
              return {
                employee: new Employee().parse(x.employee),
                plan: new EmployeePlan().parse(x.data[0]),
              };
            });
            this.calculateHours(items);

            this.rows = items;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    updateEmployee(employeeCode) {
      this.isLoading = true;
      api
        .getPlanEmployeeType({
          from: this.filter.date,
          to: this.filter.date,
          code: employeeCode,
        })
        .then((response) => {
          var index = this.rows.findIndex((x) => x.employee.employeeCode === employeeCode);
          var newData = {
            employee: new Employee().parse(response.data.employee),
            plan: new EmployeePlan().parse(response.data.data[0]),
          };
          this.$set(this.rows, index, newData);
          this.calculateHours(this.rows);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    calculateHours(items) {
      for (let item of items) {
        this.startHour = this.compareStartingHour(
          item.plan.firstPartStartingTime,
          item.plan.secondPartStartingTime,
          this.startHour
        );

        this.endHour = this.compareEndHour(
          item.plan.firstPartEndingTime,
          item.plan.secondPartEndingTime,
          this.endHour
        );
      }
    },
    compareStartingHour(date1, date2, current) {
      let start = this.$isset(date1) ? date1.getHours() : 0;
      let end = this.$isset(date2) ? date2.getHours() : 0;

      let min = Math.min(start, end);
      if (min > 0) {
        return current > 0 ? Math.min(current, min) : min;
      }
      return current;
    },
    compareEndHour(date1, date2, current) {
      let start = this.$isset(date1) ? date1.getHours() : 0;
      let end = this.$isset(date2) ? date2.getHours() : 0;

      let max = Math.max(start, end);
      if (max > 0) {
        return current > 0 ? Math.max(current, max) : max;
      }
      return current;
    },
    addWorkShift(employee) {
      EventBus.fire(SHOW_WORK_SHIFT_ADD_MODAL, {
        employee: this.$deepCopy(employee),
      });
    },
    addAbsence(employee) {
      EventBus.fire(SHOW_ABSENCE_ADD_MODAL, {
        employee: this.$deepCopy(employee),
      });
    },
  },
};
</script>

<style lang="scss">
.day-table {
  & > thead > tr > th {
    border-right: 1px solid $color-gray-2;
  }

  & > thead > tr > th:first-child,
  & > thead > tr > th:last-child {
    border-right: none;
  }

  & > thead > tr > :nth-child(1),
  & > thead > tr > :nth-child(2),
  & > tbody > tr > :nth-child(1),
  & > tbody > tr > :nth-child(2) {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
  }
}
</style>
