<template>
  <div>
    <vue-data-table
      :tableColumns="columns"
      :tableRows="rows"
      :isLoading="isLoading"
      :showRowTableAction="false"
      :empty-rows-message="$t('components.table.no_data')"
      @[needDataUpdateEvent]="init"
    >
      <template #table-row="props">
        <template v-if="['dateEnd', 'dateStart'].includes(props.cell.getColumn.id)">
          <formatted-date-component :val="props.cell.getValue" :withTime="true" />
        </template>
        <template
          v-else-if="
            ['causeOfAbsenceDescription', 'statusDescription'].includes(props.cell.getColumn.id)
          "
        >
          <a
            href="#"
            @click.prevent="showDetail(props.row.getRaw)"
            v-if="props.row.getRaw.isConfirmWaiting()"
          >
            {{ props.cell.getValue }}
          </a>
          <div v-else>
            {{ props.cell.getValue }}
          </div>
        </template>
        <template v-else-if="['attachments'].includes(props.cell.getColumn.id)">
          <div v-if="props.cell.getValue.length > 0">
            <div
              class="description"
              v-for="attachment in props.cell.getValue"
              :key="attachment.key"
            >
              <a target="_blank" href="#" @click.prevent="downLoadAttachment(attachment)">
                {{ attachment.name }}
              </a>
            </div>
          </div>
          <div v-else>&nbsp;</div>
        </template>
        <template v-else>
          <span>{{ props.cell.getValue }} </span>
        </template>
      </template>
    </vue-data-table>

    <revision-modal />
  </div>
</template>

<script>
import VueDataTable, { Column, VUE_DATA_TABLE_NEED_DATA_UPDATE } from "@/components/table";
import { SHOW_ABSENCE_REVISION_ADD_MODAL } from "@/pages/responsible/planing/data/events";
import { FormattedDateComponent } from "@/components/formatted";
import api from "@/api/appointment";

import { ABSENCES_SEARCH_CHANGED_EVENT } from "./index";
import { FinalAbsence } from "@/pages/components/data";
import RevisionModal from "@/pages/responsible/planing/absences/components/RevisionModal.vue";

import { ModalAlert } from "@/components/modal";
import { ABSENCE_UPDATED_EVENT } from "@/pages/employee/permission/absences/components";

export default {
  components: {
    VueDataTable,
    FormattedDateComponent,
    RevisionModal,
  },
  data() {
    return {
      columns: [
        new Column({
          id: "id",
          name: "pages.planing-team.absences.id",
        }),
        new Column({
          id: "employeeName",
          name: "pages.planing-team.absences.employee",
          styleHeader: {
            "min-width": "200px !important",
            "max-width": "200px !important",
            "text-align": "center",
          },
        }),
        new Column({
          id: "causeOfAbsenceDescription",
          name: "pages.planing-team.absences.type_absenses",
        }),
        new Column({
          id: "statusDescription",
          name: "pages.planing-team.absences.status",
        }),
        new Column({
          id: "comments",
          name: "pages.planing-team.absences.comments",
          styleHeader: {
            "min-width": "130px !important",
            "max-width": "130px !important",
            "text-align": "center",
          },
        }),
        new Column({
          id: "dateStart",
          name: "pages.planing-team.absences.start_date",
          styleHeader: {
            "min-width": "130px !important",
            "max-width": "130px !important",
            "text-align": "center",
          },
        }),
        new Column({
          id: "dateEnd",
          name: "pages.planing-team.absences.end_date",
          styleHeader: {
            "min-width": "130px !important",
            "max-width": "130px !important",
            "text-align": "center",
          },
        }),
        new Column({
          id: "attachments",
          name: "pages.planing-team.absences.attachments",
          styleHeader: {
            "min-width": "130px !important",
            "max-width": "130px !important",
            "text-align": "center",
          },
        }),
      ],
      rows: [],
      isLoading: false,
      employeeCode: null,
      status: null,
    };
  },
  computed: {
    needDataUpdateEvent() {
      return VUE_DATA_TABLE_NEED_DATA_UPDATE;
    },
  },
  created() {
    EventBus.listen(ABSENCES_SEARCH_CHANGED_EVENT, (args) => {
      this.employeeCode = args.employeeCode;
      this.status = args.status;
      this.init();
    });
    EventBus.listen(ABSENCE_UPDATED_EVENT, () => {
      this.init();
    });
    this.init();
  },
  beforeDestroy() {
    EventBus.off([ABSENCES_SEARCH_CHANGED_EVENT, ABSENCE_UPDATED_EVENT]);
  },
  methods: {
    async init() {
      this.isLoading = true;
      const { data } = await api.getFilteredAbsences({
        employeeCode: this.employeeCode,
        status: this.status,
      });
      this.rows = data.map((x) => new FinalAbsence().parse(x));
      this.isLoading = false;
    },
    showDetail(finalAbsence) {
      EventBus.fire(SHOW_ABSENCE_REVISION_ADD_MODAL, { appointment: finalAbsence });
    },
    downLoadAttachment(attachment) {
      this.isLoading = true;
      api
        .downloadAttachment({
          employeeCode: attachment.employeeCode,
          reference: attachment.reference,
        })
        .then((response) => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${attachment.name}.${attachment.fileExtension}`;
          link.click();
          this.isLoading = false;
        })
        .catch((error) => {
          ModalAlert.error({ message: "Error" });
          console.error(error.response);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
